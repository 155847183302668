<template>
  <draggable
    v-model="pages"
    tag="tbody"
    @start="drag = true"
    @end="sortPages"
    >
      <slot></slot>
  </draggable>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import apiClient from '@/services/axios'
import draggable from 'vuedraggable'

export default {
  components: {
    draggable,
  },
  data: () => ({
    drag: false,
  }),
  computed: {
    ...mapFields('event', {
      event: 'event',
      pages: 'pagesArray',
      sortUpdate: 'sortUpdate',
    }),
  },
  methods: {
    async sortPages() {
      // console.log(this.pages, '1 pages')
      // console.log(this.event, '1 event')
      const pagesSort = this.pages.map((page, index) => {
        return {
          page_id: page.id,
          sort: index + 1,
        }
      })
      const sort = { sort: pagesSort }
      console.log(sort, 'sort ')
      const url = '/admin/events/pages/sort'
      try {
        // console.log(this.drag, 'drag try')
        const response = await apiClient.patch(url, sort)
        console.log('pages successfully sorted', response)
        // console.log(this.pages, '2 pages')
        console.log(this.sortUpdate, '1')
        this.sortUpdate = true
        console.log(this.sortUpdate, '2')
        // console.log(this.event, '2 event')
      } catch (error) {
        console.log('Error while sorting pages', error)
      } finally {
        this.drag = false
      }
    },
  },
  mounted() {
    // console.log(this.pages, 'mounted draggable pages')
    // console.log(this.drag, 'mounted drag')
  },
}
</script>
